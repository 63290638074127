<template>
  <section v-if="!user?.is_mentor" class="become-mentor-section">
    <div class="horiz-container">
      <BecomeMentorBanner />
    </div>
  </section>
</template>

<script setup>
const user = inject('user')
</script>

<style scoped lang="scss">
.horiz-container {
  @media (max-width: 920px) {
    padding: 0;
  }
}
</style>
